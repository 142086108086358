import Link from 'next/link'
import { HiSparkles } from 'react-icons/hi2'
import { MdOutlineQueryStats, MdOutlineStorefront } from 'react-icons/md'

const cardLinks = [
  {
    name: 'Mint your NFD',
    href: '/mint',
    description: `Get started by minting an NFD. Search for the name you want and see if it's available, then connect your wallet to purchase.`,
    icon: HiSparkles,
    linkText: 'Mint search'
  },
  {
    name: 'Marketplace',
    href: '/browse',
    description:
      'Browse the marketplace to find NFDs for sale or currently in auction. You can also search for existing NFDs by name or filter by category.',
    icon: MdOutlineStorefront,
    linkText: 'Marketplace search'
  },
  {
    name: 'Analytics',
    href: '/analytics',
    description:
      'Track the latest market activity and see the highest all-time sales. More detailed analytics coming soon!',
    icon: MdOutlineQueryStats,
    linkText: 'View Analytics'
  }
]

export default function Cards() {
  return (
    <section
      className="-mt-32 max-w-md mx-auto relative z-10 px-4 sm:max-w-3xl sm:px-6 xl:max-w-7xl xl:px-8"
      aria-labelledby="get-started-heading"
      data-cy="home-get-started"
    >
      <h2 className="sr-only" id="get-started-heading">
        Get started
      </h2>
      <div className="grid grid-cols-1 gap-y-20 xl:grid-cols-3 xl:gap-y-0 xl:gap-x-8">
        {cardLinks.map((link) => (
          <Link
            key={link.name}
            href={link.href}
            className="flex flex-col bg-white rounded-2xl shadow-xl outline-brand-500 dark:bg-gray-800"
          >
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-4 inline-block bg-gradient-to-t from-brand-600 to-brand-700 rounded-xl shadow-lg transform -translate-y-1/2">
                <link.icon className="h-8 w-8 text-white dark:text-gray-100" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900 dark:text-gray-100">{link.name}</h3>
              <p className="mt-4 text-base text-gray-500 dark:text-gray-400">{link.description}</p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8 dark:bg-gray-900/50">
              <span className="text-base font-medium text-brand-500 hover:text-brand-400">
                {link.linkText}
                <span aria-hidden="true"> &rarr;</span>
              </span>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
