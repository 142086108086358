import { HiChevronRight } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

interface HeroLinkProps {
  href: string
  badge: string
  linkText: string
  className?: string
}

export default function HeroLink({ href, badge, linkText, className = '' }: HeroLinkProps) {
  return (
    <a
      href={href}
      className={classNames(
        'inline-flex items-center text-white bg-black/80 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-white/90',
        className
      )}
      target="_blank"
      rel="noreferrer"
    >
      <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-brand-400 to-brand-600 rounded-full dark:from-brand-600 dark:to-brand-600">
        {badge}
      </span>
      <span className="ml-4 text-sm">{linkText}</span>
      <HiChevronRight className="ml-2 w-5 h-5 text-gray-100/60" aria-hidden="true" />
    </a>
  )
}
