import Image from 'next/image'
import AlgorandLogo from './AlgorandLogo'
import HeroLink from './HeroLink'
import TourDesktop from './TourDesktop'
import { DOCS_URL } from 'src/data/constants'

export default function Hero() {
  return (
    <header
      className="relative pt-16 sm:pt-24 pb-36 bg-brand-500 dark:bg-gray-950"
      data-cy="home-hero"
    >
      <div className="absolute inset-0 dark:hidden">
        <Image
          className="w-full h-full object-cover object-bottom-right"
          src="/img/nfd-brand-bg.jpg"
          alt=""
          fill
          priority
        />
      </div>
      <div className="hidden absolute inset-0 dark:block">
        <Image
          className="w-full h-full object-cover object-bottom-right no-dark-adjust"
          src="/img/nfd-brand-bg_dark.jpg"
          alt=""
          fill
          priority
        />
      </div>

      <div className="relative max-w-md mx-auto px-4 pb-16 sm:pb-24 sm:max-w-3xl sm:px-6 xl:max-w-7xl xl:px-8">
        <div className="xl:flex xl:items-center xl:space-x-12">
          <div className="xl:flex-1">
            <HeroLink
              href={DOCS_URL}
              badge="Overview"
              linkText="What is an NFD?"
              className="mb-8 sm:mb-10"
            />
            <h1 className="text-5xl font-extrabold tracking-tight text-white sm:text-7xl dark:text-gray-100">
              Welcome
            </h1>
            <p className="mt-6 max-w-3xl text-xl text-brand-100 sm:mt-8 dark:text-white/60">
              NFDomains is the Algorand name service and marketplace for{' '}
              <strong className="text-white sm:whitespace-nowrap dark:text-gray-100">
                Non-Fungible Domains
              </strong>{' '}
              &mdash; unique, readable identities for your&nbsp;wallet.
            </p>
            <p className="text-brand-100 text-xs font-semibold tracking-wide mt-6 uppercase dark:text-white/60">
              Built on
              <AlgorandLogo className="w-24 h-auto inline ml-2 mb-2 opacity-80" />
            </p>
            <TourDesktop />
          </div>
          {/* <div className="mt-10 lg:mt-16 xl:mt-0 xl:w-1/2">
            <FolksReferral />
          </div> */}
        </div>
      </div>
    </header>
  )
}
