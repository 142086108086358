import Layout from 'components/Layout'
import Meta from 'components/Meta'
import Hero from 'components/home/Hero'
// import LiveAuctions from 'components/home/LiveAuctions'
import RecentSales from 'components/home/RecentSales'
import Cards from 'components/home/Cards'
import Discord from 'components/home/Discord'
import Footer from 'components/home/Footer'
import type { ReactElement } from 'react'

const Home = () => {
  return (
    <>
      <Meta />
      <div className="bg-white dark:bg-gray-900">
        <Hero />

        <main>
          <div className="bg-gray-100 dark:bg-gray-950">
            <Cards />
            {/* <LiveAuctions /> */}
            <RecentSales />
          </div>

          <Discord />
        </main>

        <Footer />
      </div>
    </>
  )
}

Home.getLayout = (page: ReactElement) => <Layout>{page}</Layout>

export default Home
