import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Link from 'next/link'
import { BiLinkExternal, BiWallet } from 'react-icons/bi'
import Alert from 'components/Alert'
import AlgoPrice from 'components/AlgoPrice'
import UserThumbnail from 'components/UserThumbnail'
import RecentSalesSkeleton from './RecentSales.skeleton'
import useRecentSales from './RecentSales.hooks'
import { classNames, truncateAddress } from 'helpers/utilities'
import { treasuryAcct } from 'src/data/nfd'

dayjs.extend(localizedFormat)

export default function RecentSales() {
  const { records, isLoading, error, usersWithThumbnails, selectedExplorer, lookupByGroupId } =
    useRecentSales()

  const renderAddress = (address: string | undefined, className = '') => {
    if (!address) {
      return <span className="mt-1 text-sm text-gray-900 dark:text-gray-100">N/A</span>
    }

    // show nfdomains.algo for mainnet treasury acct
    if (address === treasuryAcct.caAlgo[0]) {
      return <UserThumbnail nfd={treasuryAcct} className="text-gray-900 dark:text-gray-100" />
    }

    const nfd = usersWithThumbnails.data?.find((user) =>
      user.caAlgo?.some((addr) => addr === address)
    )

    if (nfd) {
      return <UserThumbnail nfd={nfd} className="text-gray-900 dark:text-gray-100" />
    }

    const addr = truncateAddress(address, { array: true })

    return (
      <Link
        href={`/address/${address}`}
        className={classNames(
          'inline-flex items-center text-sm text-gray-900 hover:text-brand-500 font-mono dark:text-gray-400 dark:hover:text-brand-500',
          className
        )}
      >
        <BiWallet className="flex-shrink-0 mr-1.5 h-5 w-5 my-1.5" aria-hidden="true" />
        {addr[0]}&hellip;{addr[1]}
      </Link>
    )
  }

  const renderContent = () => {
    if (isLoading || usersWithThumbnails.isInitialLoading) {
      return <RecentSalesSkeleton />
    }

    if (error) {
      return <Alert type="error" title={`Error fetching leaderboard`} error={error} />
    }

    if (!records || !records.length) {
      return <Alert type="info" title="No recent sales" message="No recent sales have been made." />
    }

    return (
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 xl:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 xl:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg dark:ring-gray-300/10">
            <table
              key={selectedExplorer}
              className="min-w-full divide-y divide-gray-300 dark:divide-gray-700/60"
            >
              <thead className="bg-gray-50 dark:bg-gray-800/50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-500"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-500"
                  >
                    Seller
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-500"
                  >
                    Buyer
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900 dark:text-gray-500"
                  >
                    <span className="xl:hidden">Txn</span>
                    <span className="hidden xl:inline">Transaction</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-900 dark:divide-gray-700/40">
                {records.map((record, i) => (
                  <tr key={`sales-record-${i}`} data-cy="recent-sales-row">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 xl:text-base">
                      <Link
                        href={`/name/${record.data?.name}`}
                        className="text-gray-900 hover:text-brand-500 outline-brand-500 dark:text-gray-400 dark:hover:text-brand-500"
                      >
                        {record.data?.name}
                      </Link>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 xl:text-base dark:text-gray-400">
                      <AlgoPrice price={record.price} />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderAddress(record.data?.seller)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {renderAddress(record.data?.buyer)}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                      {record.data?.groupID ? (
                        <a
                          href={lookupByGroupId(record.data.groupID)}
                          className="inline-flex items-center text-gray-500 hover:text-gray-900 font-medium outline-brand-500 group dark:hover:text-gray-400"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="hidden xl:inline">
                            {dayjs(record.timestamp).format('lll')}
                          </span>
                          <BiLinkExternal className="m-2 xl:m-0 xl:ml-3 w-5 h-5 text-gray-400 group-hover:text-brand-500 dark:text-gray-600 dark:hover:text-brand-500" />
                        </a>
                      ) : (
                        <span className="hidden xl:inline">
                          {dayjs(record.timestamp).format('lll')}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section
      className="py-24 sm:max-w-3xl sm:mx-auto xl:max-w-7xl xl:py-32"
      aria-labelledby="recent-sales-heading"
      data-cy="recent-sales"
    >
      <div className="px-4 sm:px-6 xl:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2
              className="text-3xl font-extrabold text-gray-900 dark:text-gray-100"
              id="recent-sales-heading"
            >
              Recent sales
            </h2>
            <p className="mt-2 text-gray-700 dark:text-gray-500">
              The latest primary and secondary NFD sales
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              href="/analytics?view=sales"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-brand-500 px-5 py-2.5 text-base font-medium text-white shadow-sm hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 sm:w-auto dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900"
            >
              View all<span aria-hidden="true">&nbsp;&rarr;</span>
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-col">{renderContent()}</div>
      </div>
    </section>
  )
}
