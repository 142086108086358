export default function RecentSalesSkeleton() {
  return (
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 xl:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 xl:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg dark:ring-gray-300/10">
          <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700/60">
            <thead className="bg-gray-50 dark:bg-gray-800/50">
              <tr>
                <th scope="col" className="animate-pulse py-5 pl-4 pr-3 sm:pl-6">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[48px] dark:bg-gray-700" />
                </th>
                <th scope="col" className="animate-pulse px-3 py-5">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[48px] dark:bg-gray-700" />
                </th>
                <th scope="col" className="animate-pulse px-3 py-5">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[48px] dark:bg-gray-700" />
                </th>
                <th scope="col" className="animate-pulse px-3 py-5">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[48px] dark:bg-gray-700" />
                </th>
                <th scope="col" className="animate-pulse py-5 pl-3 pr-4 sm:pr-6">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[32px] xl:max-w-[96px] dark:bg-gray-700" />
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-900 dark:divide-gray-700/40">
              {Array(10)
                .fill(null)
                .map((_, index) => (
                  <tr key={index}>
                    <td className="animate-pulse py-7 pl-4 pr-3 sm:pl-6">
                      <div className="h-3 bg-gray-200 rounded-full max-w-[120px] dark:bg-gray-700" />
                    </td>
                    <td className="animate-pulse px-3 py-7">
                      <div className="h-3 bg-gray-200 rounded-full max-w-[80px] dark:bg-gray-700" />
                    </td>
                    <td className="animate-pulse px-3 py-7">
                      <div className="h-3 bg-gray-200 rounded-full max-w-[120px] dark:bg-gray-700" />
                    </td>
                    <td className="animate-pulse px-3 py-7">
                      <div className="h-3 bg-gray-200 rounded-full max-w-[120px] dark:bg-gray-700" />
                    </td>
                    <td className="animate-pulse py-7 pl-3 pr-4 sm:pr-6">
                      <div className="h-3 bg-gray-200 rounded-full max-w-[32px] xl:max-w-[200px] dark:bg-gray-700" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
