import { Tooltip } from 'components/joyride/Tooltip'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { ACTIONS, CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride'
import { useJoyrideStore } from 'store/state/joyrideStore'
import { DOCS_URL } from 'src/data/constants'

const Joyride = dynamic(() => import('react-joyride'), { ssr: false })

const joyrideSteps: Step[] = [
  {
    target: '[data-joyride-id="jr-wallet"]',
    title: <span>Connect wallet</span>,
    content: (
      <>
        <p>Connect your wallet using one of the available providers.</p>
        <p>
          <a
            href={`${DOCS_URL}/resources/new-to-algorand`}
            className="inline-flex items-center font-semibold text-gray-600 hover:text-gray-900 group dark:font-medium dark:text-brand-500 dark:hover:text-brand-400"
            target="_blank"
            rel="noreferrer"
          >
            More about wallets
            <BiLinkExternal className="ml-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400" />
          </a>
        </p>
      </>
    ),
    placement: 'bottom',
    disableBeacon: true
  },
  {
    target: '[data-joyride-id="jr-mint"]',
    title: <span>Mint your NFD</span>,
    content: (
      <>
        <p>Use the mint search to see if the name you want is available.</p>
        <p>
          If it is available to mint, you will see the NFD&apos;s current ALGO price along with its
          approximate price in USD.
        </p>
        <p>
          <a
            href={`${DOCS_URL}/platform/mint#pricing`}
            className="inline-flex items-center font-semibold text-gray-600 hover:text-gray-900 group dark:font-medium dark:text-brand-500 dark:hover:text-brand-400"
            target="_blank"
            rel="noreferrer"
          >
            More about pricing
            <BiLinkExternal className="ml-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400" />
          </a>
        </p>
      </>
    ),
    placement: 'right',
    spotlightPadding: 0
  },
  {
    target: '[data-joyride-id="jr-marketplace"]',
    title: <span>Marketplace</span>,
    content: (
      <>
        <p>
          Here you can browse the entire NFD marketplace, filtering by name, price range, letter
          length, and more.
        </p>
        <p>
          The default view shows NFDs that are for sale, but you can clear all filters to include
          owned NFDs as well.
        </p>
      </>
    ),
    placement: 'right',
    spotlightPadding: 0
  },
  {
    target: '[data-joyride-id="jr-analytics"]',
    title: <span>Analytics</span>,
    content: (
      <>
        <p>
          Track the latest market activity and see the highest all-time sales on the Leaderboard.
        </p>
        <p>Global sales data and customizable charts coming soon!</p>
      </>
    ),
    placement: 'right',
    spotlightPadding: 0
  },
  {
    target: '[data-joyride-id="jr-manage"]',
    title: <span>Manage Dashboard</span>,
    content: (
      <>
        <p>View and manage all NFDs in your connected wallet.</p>
        <p>
          You can set an NFD&apos;s deposit address, update stored metadata, verify social media
          accounts, view sent/received offers, list an NFD for sale, and more.
        </p>
        <p>
          <a
            href={`${DOCS_URL}/platform/manage`}
            className="inline-flex items-center font-semibold text-gray-600 hover:text-gray-900 group dark:font-medium dark:text-brand-500 dark:hover:text-brand-400"
            target="_blank"
            rel="noreferrer"
          >
            Managing NFDs
            <BiLinkExternal className="ml-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400" />
          </a>
        </p>
      </>
    ),
    placement: 'right',
    spotlightPadding: 0
  },
  {
    target: '[data-joyride-id="jr-global-search"]',
    title: <span>Search</span>,
    content: (
      <>
        <p>Quickly navigate to an NFD profile from any page using the global search.</p>
      </>
    ),
    placement: 'bottom'
  },
  {
    target: '[data-joyride-id="jr-resources"]',
    title: <span>Resources</span>,
    content: (
      <>
        <p>
          Check out the{' '}
          <strong className="font-medium text-gray-900 dark:text-gray-300/80">Documentation</strong>{' '}
          for answers to frequently asked questions and to view the NFD pricing table.
        </p>
        <p>
          The{' '}
          <strong className="font-medium text-gray-900 dark:text-gray-300/80">
            Video Tutorials
          </strong>{' '}
          are a great place to start for new users, walking through all of the basic features on the
          platform.
        </p>
        <p>
          If you&apos;re interested in adding an NFD integration to your project, the documentation
          for our public API can be found here.
        </p>
      </>
    ),
    placement: 'right'
  },
  {
    target: '[data-joyride-id="jr-community"]',
    title: <span>Community</span>,
    content: (
      <>
        <p>Welcome to one of the best communities on Algorand!</p>
        <p>
          Connect with us by joining our Discord and following us on 𝕏 and Bluesky for news, feature
          announcements, giveaways and more.
        </p>
      </>
    ),
    placement: 'right'
  }
]

export default function TourDesktop() {
  const runTour = useJoyrideStore((state) => state.runTour)
  const setRunTour = useJoyrideStore((state) => state.setRunTour)
  const [helpers, setHelpers] = useState<StoreHelpers>()
  const [isLgScreen, setIsLgScreen] = useState(false)

  useEffect(() => {
    if (!isLgScreen) {
      setRunTour(false)
    }
  }, [isLgScreen, setRunTour])

  useEffect(() => {
    const handleResize = () => {
      setIsLgScreen(window.matchMedia('(min-width: 1024px)').matches)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED]

    if (finishedStatuses.includes(data.status) || data.action === ACTIONS.CLOSE) {
      setRunTour(false)
      helpers?.reset(false)
    }
  }

  return (
    <>
      <button
        type="button"
        className="hidden lg:flex mt-10 justify-center items-center group px-5 py-2.5 border border-transparent shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-500 focus:ring-white dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-[#242f40] dark:focus:ring-offset-gray-900"
        onClick={() => setRunTour(true)}
        data-cy="tour-start-btn"
      >
        <HiOutlineLightBulb
          className="-ml-1 mr-3 h-6 w-6 text-brand-500 group-hover:text-brand-600 dark:group-hover:text-brand-400"
          aria-hidden="true"
        />
        Take a quick tour
      </button>

      <Joyride
        run={runTour}
        steps={joyrideSteps}
        callback={handleJoyrideCallback}
        tooltipComponent={Tooltip}
        getHelpers={(helpers) => setHelpers(helpers)}
        continuous
        showProgress
        disableScrolling
        spotlightClicks
        styles={{
          options: {
            overlayColor: 'rgba(0, 0, 0, 0.65)',
            zIndex: 1000
          },
          overlay: {
            cursor: 'default'
          }
        }}
      />
    </>
  )
}
