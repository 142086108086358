export default function AlgorandLogo({ className = '' }) {
  return (
    <svg viewBox="0 0 1113.6 301.6" className={className}>
      <g fill="white">
        <path d="M293.1,59.5V240h-36.8V59.5H293.1z" />
        <path d="M443.3,118.3v122.1c0,37.9-29.5,61.2-65.6,61.2c-34.8,0-60.8-21.6-63.3-52.1l36.7,0.2c1.5,13,12.9,18.6,27.9,18.6 c14.2,0,27.4-7.6,27.4-27.4v-15.6c-9.4,6.1-21.4,9.7-34.8,9.7c-33.6,0-60.5-27.2-60.5-58.5c0-36.4,27-60.8,60.5-60.8 c13.5,0,25.4,3.6,34.8,9.7v-6.6L443.3,118.3z M406.5,190.2v-29.7c-7.5-10.9-17.9-14.5-28.2-14.5c-18.1,0-30.7,12.1-30.7,30.5 c0,13.5,12.6,28.3,30.7,28.3C388.6,204.7,399,201,406.5,190.2z" />
        <path d="M590.1,177.7c0,37.6-27.8,64.4-65.1,64.4c-37.6,0-65.4-26.8-65.4-64.4c0-37.3,27.8-64.6,65.4-64.6 C562.3,113.1,590.1,140.4,590.1,177.7z M554.6,177.7c0-17.7-11.6-32-29.6-32c-18.2,0-29.8,14.3-29.8,32c0,18.2,11.6,31.8,29.8,31.8 C542.9,209.4,554.6,195.9,554.6,177.7z" />
        <path d="M818.6,118.5v121.3l-36.1,0.2v-6.9c-9.4,6.1-21.4,9.9-34.8,9.9c-33.6,0-60.5-28.5-60.5-63.6c0-35.3,27-63.8,60.5-63.8 c13.5,0,25.4,3.8,34.8,9.9v-6.9L818.6,118.5z M782.5,198v-37.2c-7.6-10.8-18.2-13.8-28.8-13.8c-18.5,0-31.3,14.8-31.3,32.5 c0,17.5,12.8,32.3,31.3,32.3C764.3,211.7,774.9,208.8,782.5,198z" />
        <path d="M967,174.1V240h-35.5v-61.6c0-19.8-9.9-30.3-27.5-30.3c-9.2,0-19.3,5.3-28,15.5V240h-36.3V118.7H876v9.2 c9.9-7.9,21.4-12.2,35.1-12.2C943.5,115.7,967,138.5,967,174.1z" />
        <g id="lINT7W_1_">
          <g>
            <polygon points="239.5,239.9 202,239.9 177.6,149.2 125.1,239.9 83.2,239.9 164.2,99.5 151.2,50.7 41.9,240 0,240 138.5,0 175.2,0 191.3,59.6 229.2,59.6 203.3,104.6 			" />
          </g>
        </g>
        <path d="M1113.6,59.5V240h-36.8v-6.9c-9.4,6.1-21.4,9.9-34.8,9.9c-33.6,0-60.5-28.5-60.5-63.8c0-35.1,27-63.6,60.5-63.6 c13.5,0,25.4,3.8,34.8,9.9V59.4L1113.6,59.5L1113.6,59.5z M1076.8,198.1v-37.5c-7.7-10.9-18.4-13.9-29-13.9 c-18.6,0-31.5,14.9-31.5,32.5c0,17.9,12.9,32.8,31.5,32.8C1058.4,212,1069.1,209,1076.8,198.1z" />
        <path d="M680.6,149.3c-8,1-26.8,1.9-37.8,17.1v73.7h-36.3V118.9h36.3v9.1c8.3-7.1,20.8-11.3,37.8-9.6" />
      </g>
    </svg>
  )
}
