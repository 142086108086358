import { useMemo } from 'react'
import useAnalytics from 'api/hooks/useAnalytics'
import useAddresses from 'api/hooks/useAddresses'
import { useExplorerStore } from 'store/index'

export default function useRecentSales() {
  const { data, isInitialLoading, error, refetch } = useAnalytics({
    params: {
      event: ['minted', 'sold'],
      requireBuyer: true,
      minPrice: 1000001,
      limit: 10
    }
  })

  const users = useMemo(() => {
    return data?.results
      ? data.results.reduce<string[]>((acc, record) => {
          const accounts = [record.data?.seller, record.data?.buyer]

          accounts.forEach((user) => {
            if (user && !acc.includes(user)) {
              acc.push(user)
            }
          })

          return acc
        }, [])
      : []
  }, [data?.results])

  const usersWithThumbnails = useAddresses({
    params: {
      address: users,
      view: 'thumbnail'
    },
    options: {
      enabled: !!users && users.length > 0
    }
  })

  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByGroupId = useExplorerStore((state) => state.lookupByGroupId)

  return {
    records: data?.results,
    isLoading: isInitialLoading,
    error,
    refetch,
    users,
    usersWithThumbnails,
    selectedExplorer,
    lookupByGroupId
  }
}
