import Image from 'next/image'
import { FaDiscord } from 'react-icons/fa'

export default function Discord() {
  return (
    <section
      className="relative bg-white dark:bg-gray-900"
      aria-labelledby="join-discord"
      data-cy="home-discord"
    >
      <div
        className="hidden absolute inset-x-0 h-1/2 bg-gray-100 xl:block dark:bg-gray-950"
        aria-hidden="true"
      />
      <div className="max-w-7xl mx-auto bg-brand-500 xl:bg-transparent xl:px-8 dark:bg-gray-800 dark:xl:bg-transparent">
        <div className="xl:grid xl:grid-cols-12">
          <div className="relative z-10 xl:col-start-1 xl:row-start-1 xl:col-span-4 xl:py-16 xl:bg-transparent">
            <div
              className="absolute inset-x-0 h-1/2 bg-gray-100 xl:hidden dark:bg-gray-950"
              aria-hidden="true"
            />
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 xl:max-w-none xl:p-0">
              <div className="relative aspect-[10/6] sm:aspect-[2/1] xl:aspect-square">
                <Image
                  className="absolute inset-0 w-full h-full object-cover object-left-top rounded-3xl shadow-2xl"
                  src="/img/nfd-discord.jpg"
                  alt=""
                  fill
                />
              </div>
            </div>
          </div>

          <div className="relative bg-brand-500 xl:col-start-3 xl:row-start-1 xl:col-span-10 xl:rounded-3xl xl:grid xl:grid-cols-10 xl:items-center dark:bg-gray-800">
            <div
              className="hidden absolute inset-0 overflow-hidden rounded-3xl xl:block"
              aria-hidden="true"
            >
              <svg
                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-brand-400 dark:text-gray-700/50"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
              <svg
                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-brand-400"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
            </div>
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 xl:max-w-none xl:p-0 xl:col-start-4 xl:col-span-6">
              <h2
                className="text-3xl font-extrabold text-white dark:text-gray-100"
                id="join-discord"
              >
                Join our Discord
              </h2>
              <p className="text-lg text-white dark:text-gray-400">
                Stay up to date with the latest news, feature announcements, and more in the NFD
                Discord.
              </p>
              <p className="text-lg text-white dark:text-gray-400">
                We love to hear your feedback, suggestions and questions&hellip; ask us anything.
              </p>
              <a
                className="block w-full py-3 px-5 text-center bg-[#5865F2] border border-transparent rounded-md shadow-md text-base font-medium text-white hover:bg-[#4954CC] sm:inline-block sm:w-auto dark:bg-[#4954CC] dark:hover:bg-[#5865F2]"
                href="https://discord.gg/7XcuMTfeZP"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord className="inline -ml-1 mr-3 h-6 w-6" aria-hidden="true" />
                Let me in!
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
