import { HiX } from 'react-icons/hi'
import type { TooltipRenderProps } from 'react-joyride'

export const Tooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps
}: TooltipRenderProps) => (
  <div
    {...tooltipProps}
    className="w-96 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden dark:bg-gray-900 dark:ring-gray-800/40"
    data-cy="joyride-tooltip"
  >
    <div className="p-4" data-cy={`joyride-tooltip-${index}`}>
      <div className="flex items-start">
        {/* <div className="flex-shrink-0">
          <InboxIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        </div> */}
        <div className="ml-3 w-0 flex-1 pt-0.5">
          {step.title && (
            <p className="text-base font-medium text-gray-900 dark:text-gray-100">{step.title}</p>
          )}
          <div className="mt-2 text-sm text-gray-500 space-y-3 dark:text-gray-500">
            {step.content}
          </div>
          <footer className="mt-6 -mr-6 flex justify-between flex-row-reverse">
            {continuous ? (
              <button
                {...primaryProps}
                title={index + 1 === size ? 'Done' : 'Next'}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900"
                data-cy="joyride-tooltip-next-btn"
              >
                {index + 1 === size ? 'Done' : 'Next'} {`(${index + 1}/${size})`}
              </button>
            ) : (
              <button
                {...closeProps}
                type="button"
                className="bg-white rounded-md text-sm font-medium text-brand-600 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900"
                data-cy="joyride-tooltip-close-btn"
              >
                Close
              </button>
            )}
            {index > 0 && (
              <button
                {...backProps}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:bg-gray-800 dark:border-transparent dark:text-gray-400 dark:hover:bg-gray-700/50 dark:focus:ring-offset-gray-900"
                data-cy="joyride-tooltip-back-btn"
              >
                Back
              </button>
            )}
          </footer>
        </div>
        <div className="ml-4 flex-shrink-0 flex">
          <button
            {...skipProps}
            type="button"
            className="bg-white rounded-full p-1 inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:bg-gray-900 dark:text-gray-500 dark:hover:text-gray-300 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-400"
            data-cy="joyride-tooltip-x-btn"
          >
            <span className="sr-only">Close</span>
            <HiX className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
)
